import React, { useEffect } from "react";
import "./home.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NoBackgroundLogo from "../../assets/no-background.png";
import HammerImage from "../../assets/hammer-book.jpg";
import SmartImage from "../../assets/smart.jpg";

import {
	IconDefinition,
	findIconDefinition,
} from "@fortawesome/fontawesome-svg-core";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

export function Home() {
	library.add(fas);

	const phoneVolumeIcon: IconDefinition = findIconDefinition({
		prefix: "fas",
		iconName: "phone-volume",
	});
	const envelopeIcon: IconDefinition = findIconDefinition({
		prefix: "fas",
		iconName: "envelope",
	});
	const locationIcon: IconDefinition = findIconDefinition({
		prefix: "fas",
		iconName: "location-dot",
	});
	useEffect(() => {});

	const buttonClick = () => {
		console.log("hello world");
	};

	return (
		<div>
			<div className='container p-0'>
				<div className='col-sm-12 p-0 pl-2 text-center'>
					<div className='text-center pb-1 icon-container'>
						<img
							src={NoBackgroundLogo}
							className='img-fluid logo-page-level'
							alt='Hello Tom'
						/>
					</div>
					Moonis Law is a General Practice Law Firm specializing in
					Real Estate Transactions and Traffic moving violations.
					<br /> Let us help you with all your legal needs. Quality
					Service with Exceptional results.
					<br />
					<br />
					<b>Contact us now for a free consultation!</b>
					<br />
					<br />
				</div>
				<div className='row p-0 m-0 pb-4'>
					<div className='col-sm-4 p-0'></div>
					<div className='col-sm-2 p-0 m-0'>
						{" "}
						<FontAwesomeIcon
							className='icon'
							icon={phoneVolumeIcon}
						/>{" "}
						<span>
							<a href='tel: 631-513-0742'>631-513-0742</a>
						</span>
					</div>
					<div className='col-sm-3 p-0 m-0'>
						{" "}
						<FontAwesomeIcon
							className='icon'
							icon={envelopeIcon}
						/>{" "}
						<span>
							<a href='mailto: moonislaw1@gmail.com'>
								moonislaw1@gmail.com
							</a>
						</span>
					</div>
				</div>

				<hr className='' />

				<div className='col-sm-12 p-0 pt-4 pb-4'>
					<div className='text-center uc-text pb-4'>
						<h2>Services</h2>
					</div>

					{/* Services */}
					<div className='row m-0 pt-3 pb-5'>
						<div className='col-sm-2 p-0 m-0'></div>

						<div className='col-sm-4 p-0 m-0 text-left'>
							<p>
								<span className='bullet-point'>&#9642;</span>{" "}
								Real Estate Transactions
							</p>
							<p>
								<span className='bullet-point'>&#9642;</span>{" "}
								Traffic Moving Violations
							</p>
							<p>
								<span className='bullet-point'>&#9642;</span>{" "}
								Wills
							</p>
							<p>
								<span className='bullet-point'>&#9642;</span>{" "}
								Leases
							</p>
							<p>
								<span className='bullet-point'>&#9642;</span>{" "}
								Document Drafting
							</p>
							<p>
								<span className='bullet-point'>&#9642;</span>{" "}
								Forming Corporations, Partnerships and LLC's
							</p>
						</div>
						<div className='col-sm-1 p-0 m-0'></div>

						<div className='col-sm-4 p-0 m-0 text-center pb-1 '>
							<img
								src={HammerImage}
								className='img-fluid hammer-book-image'
								alt='Hello Tom'
							/>
						</div>
					</div>

					<hr className='pt-5 pb-3' />

					{/* Tom Resume Section */}
					<div className='row'>
						<div className='row'>
							<div className='col-sm-6 pl-3'>
								<div className='text-center pt-3'>
									<img
										src={SmartImage}
										className='img-fluid smart-image'
										alt='Hello Tom'
									/>
								</div>

								<div className='row p-0 pt-5 m-0'>
									<div className='col-sm-2'></div>
									<div className='col-sm-4 p-0 m-0'>
										{" "}
										<FontAwesomeIcon
											className='icon'
											icon={phoneVolumeIcon}
										/>{" "}
										<span>
											<a href='tel: 631-513-0792'>
												631-513-0792
											</a>
										</span>
									</div>
									<div className='col-sm-6 p-0 m-0'>
										{" "}
										<FontAwesomeIcon
											className='icon'
											icon={envelopeIcon}
										/>{" "}
										<span>
											<a href='mailto: moonislaw1@gmail.com'>
												moonislaw1@gmail.com
											</a>
										</span>
									</div>
								</div>
							</div>
							<div className='col-sm-6'>
								<h2 className='text-center'>Tom Moonis, ESQ</h2>
								<blockquote>
									<p>
										I graduated from Long Island University
										with a Bachelor's Degree in Criminal
										Justice. I then attended St. Johns
										University School of Law and earned a
										Juris Doctorate degree in 1990. I was
										admitted to the New York State Bar in
										1991 and has over 30 years experience
										practicing law.
										<br />
										<br />
										I started my career volunteering as a
										deputy city attorney with the City of
										Glen Cove working under John Terrana,
										Esq. I worked at the Nassau County
										Department of Tax Certiary and
										Condemnation. I was a partner in my own
										firm until 2002 when I branched out on
										my own, opening a solo practice office
										in Smithtown, NY.
										<br />
										<br />I specialize in Real Estate cases
										representing Lenders, Buyer and sellers,
										handling thousands of closings in my
										career. I also handled many traffic
										moving violations cases and in most
										instances has gotten points and/or fines
										reduced for his clients. I am hands on
										and personally handle all cases in the
										best interest of my clients.
									</p>
									<br />
									<footer className='blockquote-footer float-right'>
										CEO and Founder of Moonis Law&nbsp;
										<cite title='Source Title'>
											Tom Moonis, ESQ
										</cite>
									</footer>
									<br />
								</blockquote>
							</div>
						</div>
					</div>

					{/* <hr className='' /> */}

					{/* <div className='row pt-5'>
						<h2 className='text-center'>Contact</h2>
					</div> */}
				</div>
			</div>
			<footer className='footer'>
				<div className='col-md-4 col-sm-6 col-xs-12'>
				</div>
			</footer>
		</div>
	);
}
