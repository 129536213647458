import "./navbar.scss";
import React, { useState, useEffect } from "react";
import { routes } from "./routes";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import whiteLogo from '../../../assets/white-logo.png'

import {
	Collapse,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem,
} from "reactstrap";
import { Home } from "pages";

export function LocalNavbar() {
	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => setIsOpen(!isOpen);

	useEffect(() => {});

	return (
		<div className='container-fluid'>
			<Router>
				<div className='col-sm-12 p-0'>
					<Navbar color='faded' expand='md' className='nav-row m-0 p-0'>
						<div className='row w-100 m-0 p-0'>
							<div className='col-sm-3 p-0 pl-5'>
								<NavbarBrand href='/' className='uc-text'>
									<img
										src={whiteLogo}
										className='img-fluid nav-brand-logo'
										alt='Hello Tom'
									/>
								</NavbarBrand>
							</div>
						</div>
						<Collapse isOpen={isOpen} navbar>
							<Nav className='ml-auto pr-5' hidden={isOpen} navbar>
								{routes.map((route) => (
									<NavItem key={route.name}>
										<Link
											className='navbar-link'
											to={route.path}>
											{route.name}
										</Link>
									</NavItem>
								))}
							</Nav>
						</Collapse>
					</Navbar>
				</div>
				<div className='col-sm-12 p-0 component-height'>
					<Routes>
						<Route path='/' element={<Home />}></Route>
					</Routes>
				</div>
			</Router>
		</div>
	);
}
