import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import './App.scss';
import 'jquery/dist/jquery';
import { LocalNavbar as Navbar } from 'components/core/navbar/navbar';

export default function App() {
  return (
    <Navbar />
  );
}
